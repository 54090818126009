import { createPerformableConfig, TenantId } from '~/tenants/common/registry';
import { coerceLiteralNumberRequired, FIRST_VERSION_TIMESTAMP } from '~/lib/zod';
import z from 'zod';

import { TwtOrderType } from "~/tenants/twt/model/enums";

export default createPerformableConfig(
  'slideshow',
  {
    [FIRST_VERSION_TIMESTAMP]: z.object({
      version: coerceLiteralNumberRequired(FIRST_VERSION_TIMESTAMP),
      //template: z.union([z.literal('bubbles'), z.literal('basic')]),
    }),
  },
  {
    name: 'Social Media Slideshow',
    short:
      'We create a 1 to 2 minute video (time depends on number of images) formatted in both 16:9 (1920x1080 pixels) and 9:16 (1080x1920 pixels), perfect for Instagram Stories, TikTok, Facebook Reels and YouTube.',
    tenant: TenantId.TWIST_TOURS,
    thumbnail(metadata: Record<string, unknown>) {
      if ('type' in metadata && metadata.type === TwtOrderType.COMMERCIAL) {
        return 'tenant/twt/slideshow/commercial.png';
      }

      return 'tenant/twt/slideshow/residential.png';
    },
    images: [],
  },
);
