import TwtPhotosTwilight from '~/tenants/twt/performable/photo/TwtPhotosTwilight';
import TwtPhotosNeighborhood from '~/tenants/twt/performable/photo/TwtPhotosNeighborhood';
import { PersonaType } from '~/tenants/common/TenantPersona';
import { TwtPhotoType } from '~/tenants/twt/performable/photo/TwtPhotoConfig';
import TwtPhotosHighlights from '~/tenants/twt/performable/photo/TwtPhotosHighlights';
import {
  TWT_PHOTO_DOMAIN,
  TWT_PHOTO_EXTRA,
  TWT_PHOTO_NEIGHBORHOOD,
  TWT_RENTAL_10,
  TWT_RENTAL_20,
} from '~/tenants/twt/performable/photo/TwtPhotoJob';
import {
  TenantPackageAddOnGeneric,
  TenantPackageAddOnRepeat,
  TenantPackageAddOnTemplate,
} from '~/tenants/common/package';
import { format_money } from '~/components/Money';
import InputCheckbox from '~/components/input/InputCheckbox';
import React, { useState } from 'react';
import Modal, { ModalHeader, ModalWrapper } from '~/components/interactive/Modal';
import { useZodFormFieldSingle } from '~/components/zod/ZodForm';
import ZodFieldHidden from '~/components/zod/ZodFieldHidden';
import useSubmitSchema from '~/components/hooks/useSubmitSchema';
import ValibotForm from '~/components/valibot/ValibotForm';
import ValibotButton from '~/components/valibot/ValibotButton';
import ValibotFieldInput from '~/components/valibot/ValibotFieldInput';
import type { ApiDomainAvailableResponse } from '~/routes/api+/domain.available';
import Message from '~/components/Message';
import Button from '~/components/interactive/Button';
import IconDelete from '~/components/icons/streamline/line/IconDelete';
import ZodFieldSelect from '~/components/zod/ZodFieldSelect';
import Big from 'big.js';
import { TWT_AERIAL_PIN_PHOTO } from '~/tenants/twt/performable/aerial/TwtAerialJob';
import ZodFieldInput from '~/components/zod/ZodFieldInput';
import { SCHEMA_API_DOMAIN_AVAILABLE } from '~/routes/api+/schema';
import { TwtOrderType } from "~/tenants/twt/model/enums";

export default function TwtPhotosAddOns({
  persona,
  type,
  order,
}: {
  order: TwtOrderType;
  persona: PersonaType;
  type: TwtPhotoType;
}) {
  const [show, setShow] = useState(false);
  const [_domain, domain, setDomain] = useZodFormFieldSingle('domain');
  const [submitted, onSubmit] = useSubmitSchema<ApiDomainAvailableResponse>(
    SCHEMA_API_DOMAIN_AVAILABLE,
    '/api/domain/available',
  );

  return (
    <>
      {order === TwtOrderType.FOR_SALE_LISTING && (
        <>
          {type === TwtPhotoType.INTERIOR_EXTERIOR && (
            <>
              <TenantPackageAddOnGeneric
                title="Extra Photos"
                image="tenant/twt/residential/1.jpg"
                persona={PersonaType.CUSTOMER}
                description="Need more photos?"
              >
                <ZodFieldSelect
                  options={[
                    { label: 'No Extra Photos', value: '0' },
                    {
                      label: `5 Extra Photos: ${format_money(new Big(TWT_PHOTO_EXTRA).times(1).toFixed(2))}`,
                      value: '5',
                    },
                    {
                      label: `10 Extra Photos: ${format_money(new Big(TWT_PHOTO_EXTRA).times(2).toFixed(2))}`,
                      value: '10',
                    },
                    {
                      label: `15 Extra Photos: ${format_money(new Big(TWT_PHOTO_EXTRA).times(3).toFixed(2))}`,
                      value: '15',
                    },
                    {
                      label: `20 Extra Photos: ${format_money(new Big(TWT_PHOTO_EXTRA).times(4).toFixed(2))} `,
                      value: '20',
                    },
                    {
                      label: `25 Extra Photos: ${format_money(new Big(TWT_PHOTO_EXTRA).times(5).toFixed(2))} `,
                      value: '25',
                    },
                  ]}
                  name="photos"
                />
              </TenantPackageAddOnGeneric>
              <TwtPhotosHighlights persona={persona} order={order} />
            </>
          )}
          <TenantPackageAddOnTemplate
            persona={persona}
            image="tenant/twt/microsite/domain.jpg"
            title="Custom Domain"
            cost={format_money(TWT_PHOTO_DOMAIN)}
            description="Register a custom domain for your tour instead of using the default mls.tours subdomain."
            enable={
              <InputCheckbox
                checked={Boolean(domain)}
                onChange={(checked) => {
                  if (checked && !domain) {
                    setShow(true);
                  } else if (!checked && domain) {
                    setDomain(null);
                  }
                }}
              />
            }
          >
            {domain && (
              <div className="flex items-center space-x-2 pt-4">
                <Button
                  type="button"
                  variant="transparent"
                  icon={<IconDelete />}
                  onClick={(e) => {
                    e.preventDefault();

                    setDomain(null);
                  }}
                />
                <p>{domain}</p>
                <ZodFieldHidden name="domain" value={domain} />
              </div>
            )}
            <Modal show={show} onClose={() => setShow(false)}>
              <ModalWrapper>
                <ModalHeader>Domain Search</ModalHeader>
                <ValibotForm schema={SCHEMA_API_DOMAIN_AVAILABLE} onSubmit={onSubmit}>
                  <div className="p-4 w-[500px] space-y-4">
                    {submitted && submitted.available && <Message type="success">That domain is available!</Message>}
                    {submitted && !submitted.available && <Message type="error">That domain is not available.</Message>}
                    <div className="flex items-center space-x-2">
                      <span className="text-sm">www.</span>
                      <ValibotFieldInput name="domain" placeholder="123PeachtreeSt.com" />
                      {submitted && submitted.available && <ValibotButton>Search</ValibotButton>}
                    </div>
                    {submitted && submitted.available ? (
                      <div className="flex items-center border-t border-theme-separator pt-4">
                        <div className="w-28 shrink-0">
                          <Button
                            type="button"
                            onClick={() => {
                              setDomain(submitted?.domain);
                              setShow(false);
                            }}
                            variant="tenant"
                          >
                            Register
                          </Button>
                        </div>
                        <p className="text-xs text-justify">
                          By continuing you acknowledge that when this order is submitted, you will be charged upfront
                          for the domain and that fee is non-refundable.
                        </p>
                      </div>
                    ) : (
                      <ValibotButton showError>Search</ValibotButton>
                    )}
                  </div>
                </ValibotForm>
              </ModalWrapper>
            </Modal>
          </TenantPackageAddOnTemplate>
        </>
      )}
      {order !== TwtOrderType.COMMERCIAL && <TwtPhotosNeighborhood persona={persona} />}
    </>
  );
}
