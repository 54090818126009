import { TenantPackageConfig } from '~/tenants/common/registry';
import TwtPhotoConfig from '~/tenants/twt/performable/photo/TwtPhotoConfig';
import Twt3dConfig from '~/tenants/twt/performable/matterport/TwtMatterportConfig';
import { TwtOrderContext, TwtPropertyType } from '~/tenants/twt/model/TwtOrder';
import TwtFloorplanConfig from '~/tenants/twt/performable/floorplan/TwtFloorplanConfig';
import { TwtOrderType } from "~/tenants/twt/model/enums";

const TwtMatterportTourConfig: TenantPackageConfig = {
  id: '3d',
  name: 'Photos + Floor Plan + 3D Walkthrough Tour',
  description:
    'Market a property with HDR photography, a stylized floor plan, and a three dimensional virtual tour all organized into a customized microsite.',
  performables: [TwtPhotoConfig, Twt3dConfig, TwtFloorplanConfig],
  eligible: (context) =>
    (context as TwtOrderContext).metadata.type === TwtOrderType.FOR_SALE_LISTING &&
    (context as TwtOrderContext).metadata.subtype !== TwtPropertyType.LAND,
};

export default TwtMatterportTourConfig;
