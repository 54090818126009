import { DisplayData, TenantOrderLine } from '~/lib/model';
import { Big } from 'big.js';
import TwtJob from '~/tenants/twt/model/TwtJob';
import { schema_latest_version, ZodVersionedMetadata } from '~/lib/zod';
import { PersonaType } from '~/tenants/common/TenantPersona';
import TwtVirtualConfig from '~/tenants/twt/performable/virtual/TwtVirtualConfig';
import { MicrositeFileType } from '~microsite/lib/types';
import { DeliverableType } from '~common/model/Deliverable';
import { TwtOrderType } from "~/tenants/twt/model/enums";

export const TWT_VIRTUAL_PRICE = 35;

export class TwtVirtualJob extends TwtJob<typeof TwtVirtualConfig> {
  get performable() {
    return TwtVirtualConfig;
  }

  info(): DisplayData[] {
    const info = super.info();

    info.push({
      name: 'Photos',
      value: this.metadata.photos.toString(),
    });

    return info;
  }

  get deliverable(): DeliverableType {
    return DeliverableType.PHOTO;
  }

  defaultValue(): ZodVersionedMetadata<(typeof TwtVirtualConfig)['schema']> {
    // TODO: type fix?
    return {
      version: schema_latest_version(TwtVirtualConfig.schema),
      photos: 1,
    } as any;
  }

  get eligible() {
    return (
      this.order.metadata.type !== TwtOrderType.COMMERCIAL &&
      !this.order.jobs.some((j) => j.performable_id === 'photo' && j.metadata.twilight)
    );
  }

  get configurable() {
    return true;
  }

  get short() {
    return 'VIRT';
  }

  get media() {
    return { persona: PersonaType.OFFICE, microsite: MicrositeFileType.GALLERY };
  }

  revenueLines(): TenantOrderLine[] {
    return [
      {
        amount: new Big(TWT_VIRTUAL_PRICE).times(this.metadata.photos),
        taxable: true,
        description: `Virtual Twilight (${this.metadata.photos} photos)`,
        id: 'photos',
      },
    ];
  }
}
