import { TwtOrderContext } from '~/tenants/twt/model/TwtOrder';
import TwtPhotoConfig from '~/tenants/twt/performable/photo/TwtPhotoConfig';
import TwtReelConfig from '~/tenants/twt/performable/reel/TwtReelConfig';

export const TWT_JUSTIN_PERFORMABLES = [TwtPhotoConfig, TwtReelConfig];

export function twt_justin_eligible(context: TwtOrderContext) {
  return context.buyer.package_justin === true;
}

export function twt_justin_configured(order: TwtOrderContext) {
  return (
    twt_justin_eligible(order) &&
    TWT_JUSTIN_PERFORMABLES.every((p) => order.jobs.some((j) => j.performable_id === p.id))
  );
}
