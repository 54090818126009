import z from 'zod';
import {
  coerceBoolean,
  coerceLiteralNumberRequired,
  FIRST_VERSION_TIMESTAMP,
  zodTDateIso,
  zodTMoney,
  zodTMoneyOptional,
  ZodVersionedMetadata,
} from '~/lib/zod';
import { TenantCustomer } from '~/tenants/common/TenantPersona';
import { DisplayData, GeocodedAddressSchema } from '~/lib/model';

export const TwtCustomerSchema = {
  [FIRST_VERSION_TIMESTAMP]: z.object({
    version: coerceLiteralNumberRequired(FIRST_VERSION_TIMESTAMP),
    notes: z.string().optional(),
    address: z.string().optional(),
    announcement_send: coerceBoolean().optional(),
    announcement_sent: zodTDateIso().optional(),
    discount_first_dollars: zodTMoney().optional(),
    discount_first_order_id: z.string().optional(),
    discount_second_dollars: zodTMoney().optional(),
    discount_second_order_id: z.string().optional(),
    matterport_transfer: z.string().optional(),
    matterport_camera_only: coerceBoolean().optional(),
    matterport_discount_dollars: zodTMoneyOptional(),

    discount_percentage: z.coerce.number().optional(),

    local_life: coerceBoolean().optional(),

    package_jonny: coerceBoolean().optional(),
    package_justin: coerceBoolean().optional(),

    // TODO: not needed anymore?
    first_hour_photo_revenue: z.coerce.number().optional(),
    first_hour_photo_expense: z.coerce.number().optional(),
    first_hour_interior_revenue: z.coerce.number().optional(),
    first_hour_interior_expense: z.coerce.number().optional(),
    aerial_photo_revenue: z.coerce.number().optional(),
    aerial_video_revenue: z.coerce.number().optional(),
    aerial_photo_video_revenue: z.coerce.number().optional(),
  }),
};

export type TwtCustomerMetadata = ZodVersionedMetadata<typeof TwtCustomerSchema>;

export default class TwtCustomer extends TenantCustomer<TwtCustomerMetadata> {
  get info(): DisplayData[] {
    const info = super.info;

    if (this.metadata.notes) {
      info.push({ name: 'Notes', value: this.metadata.notes, provider: true });
    }

    return info;
  }
}
