import { TenantPackageConfig } from '~/tenants/common/registry';
import TwtPhotoConfig from '~/tenants/twt/performable/photo/TwtPhotoConfig';
import { TwtOrderContext } from '~/tenants/twt/model/TwtOrder';
import TwtReelConfig from '~/tenants/twt/performable/reel/TwtReelConfig';

const TwtJustinConfig = {
  id: 'justin',
  name: 'The Justin Pistorius Package',
  description: '',
  configurable: false,
  performables: [TwtPhotoConfig, TwtReelConfig],
  eligible: (context) => (context as TwtOrderContext).buyer.package_justin === true,
} satisfies TenantPackageConfig;

export default TwtJustinConfig;
