import React from 'react';
import {
  TwtPackageFloorplanAddOns,
  TwtPackageFloorplanInclude,
  TwtPackagePhotoAddOns,
  TwtPackagePhotoInclude,
  TwtPackageTimeOfDay,
} from '~/tenants/twt/package/common';
import { TenantPackageIncludes } from '~/tenants/common/package';
import { TenantPerformableAddOns } from '~/tenants/common/performable';
import { PersonaType } from '~/tenants/common/TenantPersona';

import { TwtOrderType } from "~/tenants/twt/model/enums";

export default function TwtFloorplanTourForm() {
  return (
    <>
      <TenantPackageIncludes>
        <TwtPackagePhotoInclude />
        <TwtPackageFloorplanInclude />
      </TenantPackageIncludes>
      <TwtPackageTimeOfDay />
      <TenantPerformableAddOns persona={PersonaType.CUSTOMER}>
        <TwtPackagePhotoAddOns type={TwtOrderType.FOR_SALE_LISTING} />
        <TwtPackageFloorplanAddOns />
      </TenantPerformableAddOns>
    </>
  );
}
