import { Form } from '@remix-run/react';
import z, { type ZodObject, type ZodType, ZodError } from 'zod';
import { createContext, useContext, useEffect, useState } from 'react';
import {
  extend,
  type ExtensionFactory,
  type ImmutableArray,
  type ImmutableObject,
  none,
  type State,
  useHookstate,
} from '@hookstate/core';
import { type Subscribable, subscribable } from '@hookstate/subscribable';
import { v4 } from 'uuid';

type ValidatedFormState = { [Key: string]: string | string[] | ValidatedFormState | ValidatedFormState[] };

type ExtensionData = {
  identifier: string;
};

type ValidatedFormContext = {
  state: State<ValidatedFormState, Subscribable>;
  touched: State<boolean>;
  prefix?: string;
  schema: ZodType | ZodObject<any, any, any, any>;
};

export function extension<S, E>(): ExtensionFactory<S, E, ExtensionData> {
  return () => {
    const ids = new Map<string, string>();

    return {
      onCreate(rootState, _extensionMethods) {
        return {
          identifier(state) {
            let id = ids.get(state.path.join(''));

            if (!id) {
              id = v4();

              ids.set(state.path.join(''), id);
            }

            return id;
          },
        };
      },
    };
  };
}

const context = createContext<ValidatedFormContext | null>(null);

export function useZodFormData() {
  const ctx = require_context();

  return useHookstate(ctx.state).get({ noproxy: true });
}

export function useIsZodFormValid(): true | string {
  const ctx = require_context();

  const scoped = useHookstate(ctx.state).get({ noproxy: true });

  const result = ctx.schema.safeParse(scoped);

  if (result.success) {
    return true;
  }

  return result.error.errors[0].message;
}

function getNestedState(state: State<ValidatedFormState, Subscribable>, name: string) {
  const path = name.split(/[.[\]]/).filter((p) => p !== '');

  let next = path.shift();

  if (!next) {
    throw new Error('Invalid name');
  }

  let nested = state.nested(next);

  while (path.length > 0) {
    next = path.shift();

    if (next) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      nested = nested.nested(next);
    }
  }

  return nested;
}

function useZodFormState(ctx: ValidatedFormContext, name: string) {
  const prefixed = `${ctx.prefix ?? ''}${name}`;

  const state = getNestedState(useHookstate(ctx.state), prefixed);

  return { prefixed, state };
}

function useFieldTouched(ctx: ValidatedFormContext, state: State<any, Subscribable>) {
  const formTouched = useHookstate(ctx.touched).get();

  const [touched, setTouched] = useState(false);

  useEffect(
    state.subscribe(() => {
      setTouched(true);
    }),
    [state],
  );

  return touched || formTouched;
}

export function useZodFormFieldObject<T extends {}>(
  name: string,
): [string, T | undefined, (value: T | undefined | null) => void, string | null, boolean] {
  const ctx = require_context();

  const { state, prefixed } = useZodFormState(ctx, name);

  const scoped = useHookstate(ctx.state).get({ noproxy: true, stealth: true });

  const result = ctx.schema.safeParse(scoped);

  let error: string | null = null;

  if (!result.success && result.error instanceof ZodError) {
    error = result.error.errors.filter((e) => e.path[0] === prefixed).shift()?.message ?? null;
  }

  const value = state.get();

  return [
    prefixed,
    // eslint-disable-next-line @typescript-eslint/no-base-to-string
    value === '' || value === null || value === undefined ? undefined : (value as T),
    (value: T | undefined | null) => {
      if (value === undefined || value === null) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        state.set(none);
      } else {
        state.merge(value);
      }
    },
    error,
    useFieldTouched(ctx, state),
  ];
}

export function useZodFormFieldSingle<T extends string>(
  name: string,
): [string, T | undefined, (value: string | undefined | null) => void, string | null, boolean] {
  const ctx = require_context();

  const { state, prefixed } = useZodFormState(ctx, name);

  const scoped = useHookstate(ctx.state).get({ noproxy: true, stealth: true });

  const result = ctx.schema.safeParse(scoped);

  let error: string | null = null;

  if (!result.success && result.error instanceof ZodError) {
    error = result.error.errors.filter((e) => e.path[0] === prefixed).shift()?.message ?? null;
  }

  const value = state.get();

  return [
    prefixed,
    // eslint-disable-next-line @typescript-eslint/no-base-to-string
    value === '' || value === null || value === undefined ? undefined : (value.toString() as T),
    (value: string | undefined | null) => {
      if (value === undefined || value === '' || value === null) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        state.set(none);
      } else {
        state.set(value);
      }
    },
    error,
    useFieldTouched(ctx, state),
  ];
}

export function useZodFormFieldSingleValue<T extends string>(name: string) {
  const [_name, value] = useZodFormFieldSingle<T>(name);

  return value;
}

export function useZodFormFieldArray(name: string): [
  Array<{
    name: string;
    remove: () => void;
  }>,
  (value?: Record<string, any>) => void,
  () => void,
  Record<string, unknown>[],
] {
  const ctx = require_context();

  const { state, prefixed } = useZodFormState(ctx, name) as {
    state: State<ValidatedFormState[], Subscribable>;
    prefixed: string;
  };

  const scoped = useHookstate(ctx.state);
  const arrayMissing = !('map' in state);

  useEffect(() => {
    if (!arrayMissing) {
      return;
    }

    let parent = prefixed.split('.');
    let property = parent.pop();

    if (!property) {
      throw new Error('Invalid name');
    }

    if (parent.length == 0) {
      scoped.merge({ [property]: [] });
    } else {
      getNestedState(scoped, parent.join('.')).merge({ [property]: [] });
    }
  }, []);

  if (arrayMissing) {
    return [
      [],
      () => {
        throw new Error('Cannot add to state array before state array exists');
      },
      () => {
        // nothing to clear
      },
      [],
    ];
  }

  return [
    state.map((nested, index) => ({
      name: `${prefixed}[${index}]`,
      remove() {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        nested.set(none);
      },
    })),
    (value) => {
      state.merge([value ? { ...value } : ({} as any)]);
    },
    () => {
      state.set([]);
    },
    state.get() as any,
  ];
}

export function useZodFormFieldMultiple(
  name: string,
): [string, string[], (value: string[]) => void, string | null, boolean] {
  const ctx = require_context();

  const { state, prefixed } = useZodFormState(ctx, name);

  const scoped = useHookstate(ctx.state).get({ noproxy: true, stealth: true });

  const result = ctx.schema.safeParse(scoped);

  let error: string | null = null;

  if (!result.success && result.error instanceof ZodError) {
    error = result.error.errors.filter((e) => e.path[0] === prefixed).shift()?.message ?? null;
  }

  const value = state.get() as ImmutableArray<string>;

  return [
    prefixed,
    Array.from(value || []),
    (value: string[]) => {
      state.set(value);
    },
    error,
    useFieldTouched(ctx, state),
  ];
}

type RecursivePartial<T> = {
  [P in keyof T]?: RecursivePartial<T[P]>;
};

function require_context() {
  const ctx = useContext(context);

  if (!ctx) {
    throw new Error('<NestedFields /> must be used within a <ZodForm />');
  }

  return ctx;
}

export function ZodNestedForm({ children, name }: { children: React.ReactNode; name: string }) {
  const ctx = require_context();

  if (ctx.prefix && name.startsWith(ctx.prefix)) {
    name = name.substring(ctx.prefix.length);
  }

  return (
    <context.Provider value={{ ...ctx, prefix: ctx?.prefix ? `${ctx.prefix}${name}.` : `${name}.` }}>
      {children}
    </context.Provider>
  );
}

export interface ZodFormProps<T = {}> {
  children: React.ReactNode;
  schema: ZodObject<any, any, any, T> | ZodType<T, any, any>;
  defaultValues?: RecursivePartial<T>;
  onValid?: (data: T) => void;
  method?: 'GET' | 'POST';
  className?: string;
  action?: string;
}

export default function ZodForm<T = {}>(props: ZodFormProps<T>) {
  const state = useHookstate((props.defaultValues ?? {}) as ValidatedFormState, extend(subscribable(), extension()));
  const touched = useHookstate(false);

  useEffect(() => {
    const onChange = (value: ImmutableObject<ValidatedFormState>) => {
      const { onValid } = props;

      if (!onValid) {
        return;
      }

      const result = props.schema.safeParse(value);

      if (result.success) {
        onValid(result.data);
      }
    };

    onChange(state.get({ stealth: true, noproxy: true }));

    return state.subscribe(onChange);
  }, [state, props.onValid]);

  return (
    <context.Provider value={{ state, schema: props.schema, touched }}>
      <Form
        method={props.method ?? 'POST'}
        action={props.action}
        navigate={!props.action}
        autoComplete="off"
        className={props.className ?? 'w-full'}
        onSubmit={(e) => {
          touched.set(true);

          const data = state.get({ noproxy: true, stealth: true });

          const result = props.schema.safeParse(data);

          if (!result.success) {
            e.preventDefault();
          }
        }}
      >
        {props.children}
      </Form>
    </context.Provider>
  );
}
