import FormGroup from '~/components/form/FormGroup';
import { FormHorizontal } from '~/components/form/layout';
import ZodFieldTextarea from '~/components/zod/ZodFieldTextarea';
import ZodFieldCheckbox from '~/components/zod/ZodFieldCheckbox';
import ZodFieldInput from '~/components/zod/ZodFieldInput';

export default function TwtCustomerForm() {
  return (
    <FormGroup>
      <FormHorizontal name="notes" label="Notes">
        <ZodFieldTextarea name="notes" />
      </FormHorizontal>
      <FormHorizontal name="discount_percentage" label="Overall Discount (Excluding Twilight) Percentage">
        <ZodFieldInput type="number" name="discount_percentage" />
      </FormHorizontal>
      <FormHorizontal name="matterport_discount_dollars" label="Matterport Discount (Dollars)">
        <ZodFieldInput type="number" name="matterport_discount_dollars" />
      </FormHorizontal>
      <FormHorizontal name="matterport_transfer" label="Matterport Transfer">
        <ZodFieldTextarea name="matterport_transfer" />
      </FormHorizontal>
      <FormHorizontal name="matterport_camera_only" label="Matterport Camera Required">
        <ZodFieldCheckbox name="matterport_camera_only" />
      </FormHorizontal>
      <FormHorizontal name="package_jonny" label="Jonny Wells Package">
        <ZodFieldCheckbox name="package_jonny" />
      </FormHorizontal>
      <FormHorizontal name="package_justin" label="Justin Pistorius Package">
        <ZodFieldCheckbox name="package_justin" />
      </FormHorizontal>
      <FormHorizontal name="local_life" label="Local Life">
        <ZodFieldCheckbox name="local_life" />
      </FormHorizontal>
    </FormGroup>
  );
}
